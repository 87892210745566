import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row, Table, Input, Label, Card, CardHeader, CardBody, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import axios from 'axios';
import { toast } from 'react-toastify';

import { useProfile } from "../../Components/Hooks/UserHooks";
import Loader from "../../Components/Common/Loader";
import Swal from 'sweetalert2';
const config = require('../../config.json');
const Project = () => {
    document.title = "Project | ZWC";
    const { userProfile, loading, token } = useProfile();
    const [modal_list, setmodal_list] = useState(false);
    const [modal_edit, setmodal_edit] = useState(false);
    const [data, setData] = useState({});
    const [cpData, SetCpData] = useState({});
    const [cpDatae, SetCpDatae] = useState({});
    const tog_list = () => {
        setmodal_list(!modal_list);
    };
    const edit_company = () => {
        setmodal_edit(!modal_edit);
    };

    const set_update = (json) => {
        SetCpDatae({
            ...cpDatae,
            company_name: json.company_name,
            company_logo: json.company_logo,
            company_about: json.company_about,
            company_website: json.company_website,
            company_contact_name: json.company_contact_name,
            company_email: json.company_email,
            company_operation: json.company_operation,
            id: json.id,
        });
        setmodal_edit(true);
    }

    const handelTeam = useCallback(() => {
        let axiosConfig = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${config.baseUrl}company/get_all_companys`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },

        };
        axios.request(axiosConfig)
            .then((response) => {
                console.log(response)
                setData(response.data);
            })
            .catch((error) => {
                console.log(error);
                toast.error(`Error : ${error.response?.data?.message || error.message}`);
            });
    })
    useEffect(() => {
        handelTeam()
    }, [userProfile, token])

    const AddCompany = (e) => {
        e.preventDefault();
        console.log(cpData.company_name)
        let data = {
            company_name: cpData.company_name,
            company_website: cpData.company_website,
            company_about: cpData.company_about,
            company_contact_name: cpData.company_contact_name,
            company_contact_number: cpData.company_contact_number,
            company_email: cpData.company_email,
            company_operation: cpData.company_operation,
            company_logo: '',
        };
        if (data.company_name.length < 3) {
            toast.error('Please Enter Company Name')
            return
        }
        let axiosConfig = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${config.baseUrl}company/add_company`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            data: data
        };
        console.log(axiosConfig)
        axios.request(axiosConfig)
            .then((response) => {
                console.log(response)
                toast.info(response?.data?.data?.message || response?.data?.message)
                handelTeam()
                tog_list();
            })
            .catch((error) => {
                console.log(error);
                toast.error(`Error : ${error.response?.data?.message || error.message}`);
            });
    }
    const UpdateCompany = (e) => {
        e.preventDefault();
        console.log(cpDatae)

        if (cpDatae.id < 1) {
            toast.error('Please Enter Company Name')
            return
        }
        let axiosConfig = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${config.baseUrl}company/edit_company`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            data: cpDatae
        };
        console.log(axiosConfig)
        axios.request(axiosConfig)
            .then((response) => {
                console.log(response)
                toast.info(response?.data?.data?.message || response?.data?.message)
                handelTeam()
                edit_company();
            })
            .catch((error) => {
                console.log(error);
                toast.error(`Error : ${error.response?.data?.message || error.message}`);
            });
    }
    const changeStatus = (id, status) => {
        Swal.fire({
            title: 'Are you sure? Do You Want to' + (status === 0 ? ' Activate' : ' Deactivate') + ' this project?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, do it!'
        }).then((result) => {
            console.log(result)
            if (result.isConfirmed) {
                let axiosConfig = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${config.baseUrl}company/change_status`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    data: { cid: id, company_status: (status === 0 ? 1 : 0) }
                };
                console.log(axiosConfig)
                axios.request(axiosConfig)
                    .then((response) => {
                        console.log(response)
                        toast.info(response?.data?.data?.message || response?.data?.message)
                        handelTeam()
                    })
                    .catch((error) => {
                        console.log(error);
                        toast.error(`Error : ${error.response?.data?.message || error.message}`);
                    });
            }
        })
    }
    return (
        (false) ? <Loader /> :
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title="Project List" pageTitle="Zwc" />
                        <Row className="g-4 mb-3">
                            <div className="col-sm-auto">
                                <div>
                                    <Button onClick={() => tog_list()} className="btn btn-success"><i
                                        className="ri-add-line align-bottom me-1"></i> Add New Project</Button>
                                </div>
                            </div>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardHeader className='bg-success-subtle'>
                                        <Row >
                                            <Col className="col-sm-auto">
                                                <div>
                                                    <h4 className="card-title mb-0">Project Details</h4>
                                                </div>
                                            </Col>

                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="table-responsive">
                                            <Table className="align-middle table-nowrap mb-0 table-sm table-hover">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Sr No</th>
                                                        <th scope="col">Change</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Website</th>
                                                        <th scope="col">About</th>
                                                        <th scope="col">Contact Person</th>
                                                        <th scope="col">Contact Number</th>
                                                        <th scope="col">Contact Email</th>
                                                        <th scope="col">Oprations</th>
                                                        <th scope="col">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        data?.data?.map((item, index) => {
                                                            return (
                                                                <tr key={index + 101}>
                                                                    <td scope="row">{index + 1}</td>
                                                                    <td><Button color="info" className="add-btn me-1 rounded-pill" size='sm' onClick={() => set_update(item)} ><i className="ri-pencil-fill"></i> Edit</Button></td>
                                                                    <td>{item.company_name}</td>
                                                                    <td>{item.company_website}</td>
                                                                    <td>{item.company_about}</td>
                                                                    <td>{item.company_contact_name}</td>
                                                                    <td>{item.company_contact_number}</td>
                                                                    <td>{item.company_contact_email}</td>
                                                                    <td>{item.company_operation}</td>
                                                                    <td><span onClick={() => changeStatus(item.id, item.company_status)} className={(item.company_status == 1) ? "badge bg-success" : "badge bg-danger"}>{(item.company_status == 1) ? "Active" : "In-Active"}</span></td>

                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/* Add Modal */}
                <Modal isOpen={modal_list} toggle={() => { tog_list(); }} centered size="lg" >
                    <ModalHeader className=" p-3 text-white" toggle={() => { tog_list(); }}> Add Project </ModalHeader>
                    <form className="tablelist-form" onSubmit={AddCompany}>
                        <ModalBody>
                            <Row>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="companyName" className="form-label">Company Name</label>
                                    <input type="text" id="companyName" className="form-control" placeholder="Enter Name" required onChange={(e) => SetCpData({ ...cpData, company_name: e.target.value })} />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="website" className="form-label">Company Website</label>
                                    <input type="text" id="website" className="form-control" placeholder="Enter Website" required
                                        onChange={(e) => SetCpData({ ...cpData, company_website: e.target.value })} />
                                </div>
                                <div className="mb-3 col-md-12">
                                    <label htmlFor="about" className="form-label">About Company</label>
                                    <input type="text" id="about" className="form-control" placeholder="Enter About Company" required
                                        onChange={(e) => SetCpData({ ...cpData, company_about: e.target.value })} />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="conatct-person" className="form-label">Conatct Person</label>
                                    <input type="text" id="conatct-person" className="form-control" placeholder="Enter Conatct Person Name" required
                                        onChange={(e) => SetCpData({ ...cpData, company_contact_name: e.target.value })} />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="conatct-number" className="form-label">Conatct Number</label>
                                    <input type="text" id="conatct-number" className="form-control" placeholder="Enter Contact number" required
                                        onChange={(e) => SetCpData({ ...cpData, company_contact_number: e.target.value })} />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="conatct-email" className="form-label">Contact Email</label>
                                    <input type="email" id="conatct-email" className="form-control" placeholder="Enter Contact Email" required
                                        onChange={(e) => SetCpData({ ...cpData, company_email: e.target.value })} />
                                </div>

                                <div className="mb-3 col-md-6">
                                    <label htmlFor="opration" className="form-label">Oprations</label>
                                    <input type="text" id="opration" className="form-control" placeholder="Enter Oprations of Comapny" required
                                        onChange={(e) => SetCpData({ ...cpData, company_operation: e.target.value })} />
                                </div>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <div className="hstack gap-2 justify-content-center text-center">
                                <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Close</button>
                                <button type="submit" className="btn btn-success" id="add-btn" >Add Compant</button>
                                {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                            </div>
                        </ModalFooter>
                    </form>
                </Modal>
                {/* Update Modal */}
                <Modal isOpen={modal_edit} toggle={() => { edit_company(); }} centered size="lg" >
                    <ModalHeader className=" opacity-50 p-3 text-white " toggle={() => { edit_company(); }}> Update Comapny Incromation </ModalHeader>
                    <form className="tablelist-form" onSubmit={UpdateCompany}>
                        <ModalBody>
                            <Row>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="companyName" className="form-label">Company Name</label>
                                    <input type="text" id="companyName" className="form-control" placeholder="Enter Name" required
                                        value={cpDatae.company_name} onChange={(e) => SetCpDatae({ ...cpDatae, company_name: e.target.value })}
                                    />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="website" className="form-label">Company Website</label>
                                    <input type="text" id="website" className="form-control" placeholder="Enter Website" required
                                        value={cpDatae.company_website} onChange={(e) => SetCpDatae({ ...cpDatae, company_website: e.target.value })}
                                    />
                                </div>
                                <div className="mb-3 col-md-12">
                                    <label htmlFor="about" className="form-label">About Company</label>
                                    <input type="text" id="about" className="form-control" placeholder="Enter Website" required
                                        value={cpDatae.company_about} onChange={(e) => SetCpDatae({ ...cpDatae, company_about: e.target.value })}
                                    />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="conatct-person" className="form-label">Conatct Person</label>
                                    <input type="text" id="conatct-person" className="form-control" placeholder="Enter Conatct Person Name" required
                                        value={cpDatae.company_contact_name} onChange={(e) => SetCpDatae({ ...cpDatae, company_contact_name: e.target.value })}
                                    />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="conatct-number" className="form-label">Conatct Number</label>
                                    <input type="text" id="conatct-number" className="form-control" placeholder="Enter Contact number" required
                                        value={cpDatae.company_contact_number} onChange={(e) => SetCpDatae({ ...cpDatae, company_contact_number: e.target.value })}
                                    />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="conatct-email" className="form-label">Contact Email</label>
                                    <input type="email" id="conatct-email" className="form-control" placeholder="Enter Contact Email" required
                                        value={cpDatae.company_email} onChange={(e) => SetCpDatae({ ...cpDatae, company_email: e.target.value })}
                                    />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="opration" className="form-label"> Operations</label>
                                    <input type="text" id="opration" className="form-control" placeholder="Enter Contact Email" required
                                        value={cpDatae.company_operation} onChange={(e) => SetCpDatae({ ...cpDatae, company_operation: e.target.value })}
                                    />
                                </div>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <div className="hstack gap-2 justify-content-center text-center">
                                <button type="button" className="btn btn-light" onClick={() => setmodal_edit(false)}>Close</button>
                                <button type="submit" className="btn btn-success" id="add-btn">Update Compant</button>
                                {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                            </div>
                        </ModalFooter>
                    </form>
                </Modal>
            </React.Fragment>
    );
};

export default Project;