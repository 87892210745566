import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row, Table, Input, Label, Card, CardHeader, CardBody, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import axios from 'axios';
import { toast } from 'react-toastify';
import Select from "react-select";
import { useProfile } from "../../Components/Hooks/UserHooks";
import Loader from "../../Components/Common/Loader";
import Swal from 'sweetalert2'
const config = require('../../config.json');
const UserList = () => {
    document.title = "Project User Mapping | ZWC";
    const { userProfile, loading, token } = useProfile();
    const [modal_list, setmodal_list] = useState(false);
    const [modal_edit, setmodal_edit] = useState(false);
    const [data, setData] = useState({});
    const [dissortBy, setdissortBy] = useState(null);
    const [disable, setdisable] = useState(false);
    const dissortbyname = [
        {
            options: [
                { label: "London", value: "London" },
                { label: "Manchester", value: "Manchester" },
                { label: "Liverpool", value: "Liverpool" }
            ]
        },
    ];

    const username = [
        {
            options: [
                { label: "Alabama", value: "AL" },
                { label: "Madrid", value: "MA" },
                { label: "Toronto", value: "TO" },
                { label: "Londan", value: "LO" },
                { label: "Wyoming", value: "WY" }
            ],
        },
    ];
    const tog_list = () => {
        setmodal_list(!modal_list);
    };
    const edit_company = () => {
        setmodal_edit(!modal_edit);
    };

    const set_update = (json) => {
        setmodal_edit(true);
    }

    const useraction = (id, status) => {
        let st = 'Activate';
        if (status === 0) {
            st = "De-activeate";
        }
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to " + st + " this user?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, do it!'
        }).then((result) => {

        })
    }
    const handelUsers = useCallback(() => {
        let axiosConfig = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${config.baseUrl}admin/users`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        };
        axios.request(axiosConfig)
            .then((response) => {
                console.log(response.data.data)
                setData(response.data);
            })
            .catch((error) => {
                console.log(error);
                toast.error(`Error : ${error.response?.data?.message || error.message}`);
            });
    })
    useEffect(() => {
        handelUsers()
    }, [userProfile, token])
    return (
        (false) ? <Loader /> :
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title="Project User Mapping" pageTitle="Zwc" />
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardHeader className='bg-success-subtle'>
                                        <Row >
                                            <Col className="col-sm-auto">
                                                <div>
                                                    <h4 className="card-title mb-0">Search Project User's</h4>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col xs='12' className='col-sm-auto'>
                                                <div className="mb-3 ">
                                                    <label htmlFor="uname" className="form-label">User Name</label>
                                                    <input type="text" id="uname" className="form-control" placeholder="Name of User" />
                                                </div>
                                            </Col>
                                            <Col xs='12' className='col-sm-auto'>
                                                <div className="mb-3 ">
                                                    <label htmlFor="uemail" className="form-label">User Email</label>
                                                    <input type="email" id="uemail" className="form-control" placeholder="Email of User" />
                                                </div>
                                            </Col>
                                            <Col xs='12' className='col-sm-auto'>
                                                <div className="mb-3 ">
                                                    <label htmlFor="ucontact" className="form-label">User Conatct Number</label>
                                                    <input type="email" id="ucontact" className="form-control" placeholder="Conatact Number" />
                                                </div>
                                            </Col>
                                            <Col xs='12' className='col-sm-auto'>
                                                <div className="d-flex justify-content-cecnter mt-4">
                                                    <Button color="info" className="add-btn me-1 rounded-pill" size='md' id="search"><i className="ri-add-line align-bottom me-1"></i> Search Now</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardHeader className='bg-success-subtle'>
                                        <Row >
                                            <Col className="col-sm-auto">
                                                <div>
                                                    <h4 className="card-title mb-0"> Users Details</h4>
                                                </div>
                                            </Col>
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <Button color="info" className="add-btn me-1 rounded-pill" size='sm' onClick={() => tog_list()} id="create-btn"><i className="ri-add-line align-bottom me-1"></i> Add</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="table-responsive">
                                            <Table className="align-middle table-nowrap mb-0 table-sm table-hover">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Sr No</th>
                                                        <th scope="col">Edit</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Mobile</th>
                                                        <th scope="col">Designation</th>
                                                        <th scope="col">Contact Number </th>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        data?.data?.map((item, index) => {
                                                            return (
                                                                <tr>
                                                                    <td>{index + 1}</td>
                                                                    <td><Button color="info" className="add-btn me-1 rounded-pill" size='sm' onClick={() => set_update(item)} ><i className="ri-pencil-fill"></i> Edit</Button></td>
                                                                    <td>{item.name}</td>
                                                                    <td>{item.mobile}</td>
                                                                    <td>{item.designation_name}</td>
                                                                    <td>{item.contact_other}</td>
                                                                    <td>{item.company_email}</td>
                                                                    <td><span className={(item.status == 1) ? "badge bg-success" : "badge bg-danger"}>{(item.status == 1) ? "Active" : "In-Active"}</span></td>
                                                                    <td><Button color="warning" className="link-danger rounded-pill" size='sm' onClick={() => useraction(item.id, item.status)} ><i className="ri-delete-bin-line"></i>{(item.status == 0) ? "Active" : "Deactive"}</Button></td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/* Add Modal */}
                <Modal isOpen={modal_list} toggle={() => { tog_list(); }} centered size="lg" >
                    <ModalHeader className=" p-3 text-white" toggle={() => { tog_list(); }}> Change User to Project with Designation </ModalHeader>
                    <form className="tablelist-form">
                        <ModalBody>
                            <Row>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="fname" className="form-label">Full Name</label>
                                    <input type="text" id="fname" className="form-control" placeholder="Enter Name" required />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="website" className="form-label">Email</label>
                                    <input type="text" id="email" className="form-control" placeholder="Enter Website" required />
                                </div>
                                <div className="mb-3 col-md-12">
                                    <label htmlFor="about" className="form-label">Mobile</label>
                                    <input type="text" id="mobile" className="form-control" placeholder="Enter Website" required />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="ocontact" className="form-label">Other Conatct Number </label>
                                    <input type="text" id="ocontact" className="form-control" placeholder="Enter Conatct Person Name" required />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="oemail" className="form-label">Other Email</label>
                                    <input type="text" id="oemail" className="form-control" placeholder="Enter Contact number" required />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="opration" className="form-label">Designation</label>
                                    <Select
                                        value={username}
                                        onChange={(sortBy) => {
                                            setdissortBy(sortBy);
                                        }}
                                        options={dissortbyname}
                                        className="js-example-disabled mb-0"
                                        isDisabled={disable}
                                    />
                                </div>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <div className="hstack gap-2 justify-content-center text-center">
                                <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Close</button>
                                <button type="submit" className="btn btn-success" id="add-btn">Add User Now</button>
                                {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                            </div>
                        </ModalFooter>
                    </form>
                </Modal>
                {/* Update Modal */}
                <Modal isOpen={modal_edit} toggle={() => { edit_company(); }} centered size="lg" >
                    <ModalHeader className=" opacity-50 p-3 text-white " toggle={() => { edit_company(); }}> Update User Designation </ModalHeader>
                    <form className="tablelist-form">
                        <ModalBody>
                            <Row>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="fname" className="form-label">Full Name</label>
                                    <input type="text" id="fname" className="form-control" placeholder="Enter Name" required />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="website" className="form-label">Email</label>
                                    <input type="text" id="email" className="form-control" placeholder="Enter Website" required />
                                </div>
                                <div className="mb-3 col-md-12">
                                    <label htmlFor="about" className="form-label">Mobile</label>
                                    <input type="text" id="mobile" className="form-control" placeholder="Enter Website" required />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="ocontact" className="form-label">Other Conatct Number </label>
                                    <input type="text" id="ocontact" className="form-control" placeholder="Enter Conatct Person Name" required />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="oemail" className="form-label">Other Email</label>
                                    <input type="text" id="oemail" className="form-control" placeholder="Enter Contact number" required />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="opration" className="form-label">Designation</label>
                                    <Select
                                        value={username}
                                        onChange={(sortBy) => {
                                            setdissortBy(sortBy);
                                        }}
                                        options={dissortbyname}
                                        className="js-example-disabled mb-0"
                                        isDisabled={disable}
                                    />
                                </div>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <div className="hstack gap-2 justify-content-center text-center">
                                <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Close</button>
                                <button type="submit" className="btn btn-success" id="add-btn">Change Now</button>
                                {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                            </div>
                        </ModalFooter>
                    </form>
                </Modal>
            </React.Fragment>
    );
};

export default UserList;