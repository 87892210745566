import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
  const history = useNavigate();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isApps, setIsApps] = useState(false);
  const [project, setIsProjct] = useState(false);
  const [wast, setIsWast] = useState(false);
  const [master, SetIsMaster] = useState(false);
  const [user, SetIsUser] = useState(false);
  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "project") {
      setIsProjct(false);
    }
    if (iscurrentState !== "Apps") {
      setIsApps(false);
    }
    if (iscurrentState !== "master") {
      SetIsMaster(false);
    }
    if (iscurrentState !== "wast") {
      setIsWast(false);
    }
    if (iscurrentState !== "user") {
      SetIsUser(false);
    }

  }, [
    history,
    iscurrentState,
    isDashboard,
    project,
    master,

  ]);

  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "dashboard",
      label: "Dashboards",
      icon: "ri-dashboard-2-line",
      link: "/dashboard",
      stateVariables: isDashboard,
    },
    {
      id: "project",
      label: "Projects",
      icon: "ri-apps-2-line",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsProjct(!project);
        setIscurrentState("project");
        updateIconSidebar(e);
      },
      stateVariables: project,
      subItems: [
        {
          id: 1,
          label: "Project's List",
          link: "/project",
          parentId: "project",
        },
        {
          id: 2,
          label: "Projet Users",
          link: "/project-user",
          parentId: "project",
        },
        {
          id: 3,
          label: "Projet Loaction",
          link: "/project-location",
          parentId: "project",
        },
        {
          id: 4,
          label: "Projet Loaction Phase",
          link: "/project-location-phase",
          parentId: "project",
        },
        {
          id: 5,
          label: "Projet Questions",
          link: "/project-question",
          parentId: "project",
        },
      ],

    },
    {
      id: "wast",
      label: "Wast Management",
      icon: "ri-apps-2-line",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsWast(!wast);
        setIscurrentState("project");
        updateIconSidebar(e);
      },
      stateVariables: wast,
      subItems: [
        {
          id: 1,
          label: "Waste Genration Point",
          link: "/waste-genration-point",
          parentId: "wast",
        },
        {
          id: 2,
          label: "Waste Type",
          link: "/waste-type",
          parentId: "wast",
        },
        {
          id: 3,
          label: "Waste Amount ",
          link: "/waste-amount",
          parentId: "wast",
        },

      ],

    },
    {
      id: "user",
      label: "User's",
      icon: "ri-mastercard-fill",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        SetIsUser(!user);
        setIscurrentState("user");
        updateIconSidebar(e);
      },
      stateVariables: user,
      subItems: [
        {
          id: 1,
          label: "User List",
          link: "/user-list",
          parentId: "user",
        },
        {
          id: 2,
          label: "User Projects",
          link: "/user-project",
          parentId: "user",
        }
      ],

    },
    {
      id: "Masters",
      label: "Master",
      icon: "ri-mastercard-fill",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        SetIsMaster(!master);
        setIscurrentState("master");
        updateIconSidebar(e);
      },
      stateVariables: master,
      subItems: [
        {
          id: 3,
          label: "Master Designation",
          link: "/designation",
          parentId: "master",
        },
        {
          id: 4,
          label: "Main Role",
          link: "/role",
          parentId: "master",
        },
        {
          id: 5,
          label: "Master Phases",
          link: "/phase",
          parentId: "master",
        },
        {
          id: 6,
          label: "Master Questions",
          link: "/master-question",
          parentId: "master",
        },
        {
          id: 6,
          label: "Wast Genration type",
          link: "/master-wast-genration-type",
          parentId: "master",
        },
      ],

    },

  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
