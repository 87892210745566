import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row, Table, Input, Label, Card, CardHeader, CardBody, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import axios from 'axios';
import { toast } from 'react-toastify';
import Select from "react-select";
import { useProfile } from "../../Components/Hooks/UserHooks";
import Loader from "../../Components/Common/Loader";
import Swal from 'sweetalert2';
const config = require('../../config.json');
const ProjectLocationPhase = () => {
    document.title = "Project Location Mapping | ZWC";
    const { userProfile, loading, token } = useProfile();
    const [modal_list, setmodal_list] = useState(false);
    const [modal_edit, setmodal_edit] = useState(false);
    const [data, setData] = useState({});
    const [dissortBy, setdissortBy] = useState(null);
    const [disable, setdisable] = useState(false);
    const dissortbyname = [
        {
            options: [
                { label: "London", value: "London" },
                { label: "Manchester", value: "Manchester" },
                { label: "Liverpool", value: "Liverpool" }
            ]
        },
    ];

    const username = [
        {
            options: [
                { label: "Alabama", value: "AL" },
                { label: "Madrid", value: "MA" },
                { label: "Toronto", value: "TO" },
                { label: "Londan", value: "LO" },
                { label: "Wyoming", value: "WY" }
            ],
        },
    ];
    const tog_list = () => {
        setmodal_list(!modal_list);
    };
    const edit_company = () => {
        setmodal_edit(!modal_edit);
    };

    const set_update = (json) => {
        setmodal_edit(true);
    }

    const start = (id, ph) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to start this " + ph + "?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, do it!'
        }).then((result) => {
            toast.success("Project Phase" + ph + " Started Successfully", { autoClose: 3000 });
        })
    }
    const handelTeam = useCallback(() => {
        let axiosConfig = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${config.baseUrl}company/get_all_companys`,
            headers: {
                'Content-Type': 'application/json'
            },
        };
        axios.request(axiosConfig)
            .then((response) => {
                console.log(response)
                setData(response);
            })
            .catch((error) => {
                console.log(error);
                toast.error(`Error : ${error.response?.data?.message || error.message}`);
            });
    })
    useEffect(() => {
        //handelTeam()
    }, [userProfile])
    return (
        (false) ? <Loader /> :
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title="Project User Mapping" pageTitle="Zwc" />
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardHeader className='bg-success-subtle'>
                                        <Row >
                                            <Col className="col-12">
                                                <div>
                                                    <h4 className="card-title mb-0">Select Project for Location</h4>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col className='col-5'>
                                                <label htmlFor="companyName" className="form-label">Select Project</label>
                                                <Select
                                                    value={dissortBy}
                                                    onChange={(sortBy) => {
                                                        setdissortBy(sortBy);
                                                    }}
                                                    options={dissortbyname}
                                                    className="js-example-disabled mb-0"
                                                    isDisabled={disable}
                                                />
                                            </Col>
                                            <Col className='col-5'>
                                                <label htmlFor="companyName" className="form-label">Select Project Loaction</label>
                                                <Select
                                                    value={dissortBy}
                                                    onChange={(sortBy) => {
                                                        setdissortBy(sortBy);
                                                    }}
                                                    options={dissortbyname}
                                                    className="js-example-disabled mb-0"
                                                    isDisabled={disable}
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardHeader className='bg-success-subtle'>
                                        <Row >
                                            <Col className="col-sm-auto">
                                                <div>
                                                    <h4 className="card-title mb-0">Project Loaction Phase Details</h4>
                                                </div>
                                            </Col>
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <Button color="info" className="add-btn me-1 rounded-pill" size='sm' onClick={() => tog_list()} id="create-btn"><i className="ri-add-line align-bottom me-1"></i> Add</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="table-responsive">
                                            <Table className="align-middle table-nowrap mb-0 table-sm table-hover">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Sr No</th>
                                                        <th scope="col">Project Name</th>
                                                        <th scope="col">Loaction Name</th>
                                                        <th scope="col">Loaction Address </th>
                                                        <th scope="col">Phase Name</th>
                                                        <th scope="col">About Phase</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Started Date</th>
                                                        <th scope="col">Start By</th>
                                                        <th scope="col">End Date</th>
                                                        <th scope="col">Entery By</th>
                                                        <th scope="col">Entery Date</th>
                                                        <th scope="col">End By</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr>
                                                        <td scope="row">#1</td>
                                                        <td>Project Name</td>
                                                        <td>Loaction Name</td>
                                                        <td>Loaction address</td>
                                                        <td>Phase Name</td>
                                                        <td>About Phase</td>
                                                        <td><span className="badge bg-success">Not Started</span></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Button color="info" className="rounded-pill" size='sm' onClick={() => start(1, 'Test')} ><i className="ri-delete-bin-line"></i>Start</Button></td>
                                                    </tr>

                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/* Add Modal */}
                <Modal isOpen={modal_list} toggle={() => { tog_list(); }} centered size="lg" >
                    <ModalHeader className=" p-3 text-white" toggle={() => { tog_list(); }}> Add Phase to Project Location</ModalHeader>
                    <form className="tablelist-form">
                        <ModalBody>
                            <Row>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="companyName" className="form-label">Project Name</label>
                                    <input type="text" id="companyName" className="form-control" />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="companyName" className="form-label">Loaction Name</label>
                                    <input type="text" id="companyName" className="form-control" />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="companyName" className="form-label">Loaction Address</label>
                                    <input type="text" id="companyName" className="form-control" />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="opration" className="form-label">Select Phase </label>
                                    <Select
                                        value={dissortBy}
                                        onChange={(sortBy) => {
                                            setdissortBy(sortBy);
                                        }}
                                        options={dissortbyname}
                                        className="js-example-disabled mb-0"
                                        isDisabled={disable}
                                    />
                                </div>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <div className="hstack gap-2 justify-content-center text-center">
                                <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Close</button>
                                <button type="submit" className="btn btn-success" id="add-btn">Add Phase to Loaction Now</button>
                                {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                            </div>
                        </ModalFooter>
                    </form>
                </Modal>
                {/* Update Modal */}
                <Modal isOpen={modal_edit} toggle={() => { edit_company(); }} centered size="lg" >
                    <ModalHeader className=" opacity-50 p-3 text-white " toggle={() => { edit_company(); }}> Update Location of Project </ModalHeader>
                    <form className="tablelist-form">
                        <ModalBody>
                            <Row>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="companyName" className="form-label">Project Name</label>
                                    <input type="text" id="companyName" className="form-control" />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="companyName" className="form-label">Loaction Name</label>
                                    <input type="text" id="companyName" className="form-control" />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="companyName" className="form-label">Loaction Address</label>
                                    <input type="text" id="companyName" className="form-control" />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="companyName" className="form-label">Loaction Map Coordinate</label>
                                    <input type="text" id="companyName" className="form-control" />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="companyName" className="form-label">Loaction Contact Person Name</label>
                                    <input type="text" id="companyName" className="form-control" />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="companyName" className="form-label">Loaction Contact Person Mobile</label>
                                    <input type="text" id="companyName" className="form-control" />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="companyName" className="form-label">Loaction Total Workers</label>
                                    <input type="text" id="companyName" className="form-control" />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="companyName" className="form-label">About Loaction</label>
                                    <input type="text" id="companyName" className="form-control" />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="about" className="form-label">Status</label>
                                    <select className="form-select" aria-label="Default select example">
                                        <option value={1}>Active</option>
                                        <option value={0}>In-active</option>
                                    </select>
                                </div>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <div className="hstack gap-2 justify-content-center text-center">
                                <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Close</button>
                                <button type="submit" className="btn btn-success" id="add-btn">Change Now</button>
                                {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                            </div>
                        </ModalFooter>
                    </form>
                </Modal>
            </React.Fragment>
    );
};

export default ProjectLocationPhase;