import React from "react";
import { Navigate } from "react-router-dom";


import Starter from '../pages/admin/Starter';
import Project from '../pages/admin/Project';
import ProjectUser from '../pages/admin/Projectuser';
import Login from "../pages/Authentication/Login";
import UserList from "../pages/admin/Userlist";
import UserProject from "../pages/admin/UserProject";
import Designation from "../pages/master/Designation";
import Role from "../pages/master/Role";
import Phase from "../pages/master/Phase";
import Question from "../pages/master/Question";
import MasterWastGenrationType from "../pages/master/MasterWastGenrationType";
import ProjectLocation from "../pages/admin/ProjectLocation";
import ProjectLocationPhase from "../pages/admin/ProjectLocationPhase";
import ProjectQuestions from "../pages/admin/ProjectQuestions";

const authProtectedRoutes = [
  { path: "/index", component: <Starter /> },


  //Pages
  { path: "/project", component: <Project /> },
  { path: "/project-user", component: <ProjectUser /> },
  { path: "/user-list", component: <UserList /> },
  { path: "/user-project", component: <UserProject /> },
  { path: "/designation", component: <Designation /> },
  { path: "/role", component: <Role /> },
  { path: "/phase", component: <Phase /> },
  { path: "/master-question", component: <Question /> },
  { path: "/master-wast-genration-type", component: <MasterWastGenrationType /> },
  { path: "/project-location", component: <ProjectLocation /> },
  { path: "/project-location-phase", component: <ProjectLocationPhase /> },
  { path: "/project-question", component: <ProjectQuestions /> },

  {
    path: "/",
    exact: true,
    component: <Navigate to="/index" />,
  },
  { path: "*", component: <Navigate to="/index" /> },
];

const publicRoutes = [
  // Authentication Page

  { path: "/login", component: <Login /> },

];

export { authProtectedRoutes, publicRoutes };