import { useEffect, useState } from "react";

const getLoggedinUser = () => {
  const user = sessionStorage.getItem("authUser");

  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};
const useProfile = () => {
  const userProfileSession = getLoggedinUser();
  var token =
    userProfileSession &&
    userProfileSession["token"];
  const [loading, setLoading] = useState(userProfileSession ? false : true);
  const [userProfile, setUserProfile] = useState(
    userProfileSession ? userProfileSession : null
  );

  useEffect(() => {
    const userProfileSession = getLoggedinUser();
    var token =
      userProfileSession &&
      userProfileSession["token"];
    setUserProfile(userProfileSession ? userProfileSession : null);
    setLoading(token ? false : true);
  }, []);


  return { userProfile, loading, token };
};

export { useProfile };