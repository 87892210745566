import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row, Table, Input, Label, Card, CardHeader, CardBody, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import axios from 'axios';
import { toast } from 'react-toastify';

import { useProfile } from "../../Components/Hooks/UserHooks";
import Loader from "../../Components/Common/Loader";
import Swal from 'sweetalert2';
import Select from "react-select";
const config = require('../../config.json');
const MasterWastGenrationType = () => {
    document.title = "Project | ZWC";
    const { userProfile, loading, token } = useProfile();
    const [modal_list, setmodal_list] = useState(false);
    const [modal_edit, setmodal_edit] = useState(false);
    const [data, setData] = useState({});
    const [dissortBy, setdissortBy] = useState(null);
    const [disable, setdisable] = useState(false);
    const tog_list = () => {
        setmodal_list(!modal_list);
    };
    const edit_company = () => {
        setmodal_edit(!modal_edit);
    };

    const set_update = (json) => {
        setmodal_edit(true);
    }
    const dissortbyname = [
        {
            options: [
                { label: "Administrator", value: "London" },
                { label: "User", value: "Manchester" },
                { label: "Analyst", value: "Liverpool" }
            ]
        },
    ];
    const handelTeam = useCallback(() => {
        let axiosConfig = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${config.baseUrl}company/get_all_companys`,
            headers: {
                'Content-Type': 'application/json'
            },
        };
        axios.request(axiosConfig)
            .then((response) => {
                console.log(response)
                setData(response);
            })
            .catch((error) => {
                console.log(error);
                toast.error(`Error : ${error.response?.data?.message || error.message}`);
            });
    })
    useEffect(() => {
        //handelTeam()
    }, [userProfile])
    return (
        (false) ? <Loader /> :
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title="Master Wast Genration Type" pageTitle="Zwc" />
                        <Row>
                            <Col xs={8}>
                                <Card>
                                    <CardHeader className='bg-success-subtle'>
                                        <Row >
                                            <Col className="col-sm-auto">
                                                <div>
                                                    <h4 className="card-title mb-0">Master Wast Genration Type for Wast Managment</h4>
                                                </div>
                                            </Col>
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <Button color="info" className="add-btn me-1 rounded-pill" size='sm' onClick={() => tog_list()} id="create-btn"><i className="ri-add-line align-bottom me-1"></i> Add</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="table-responsive">
                                            <Table className="align-middle table-nowrap mb-0 table-sm table-hover">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Sr No</th>
                                                        <th scope="col">Change</th>
                                                        <th scope="col">Genration Type </th>
                                                        <th scope="col">About Genration Type </th>
                                                        <th scope="col">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr>
                                                        <td scope="row">#1</td>
                                                        <td><Button className="link-danger fs-15 btn-sm btn-warning" onClick={() => set_update(1)} ><i className="ri-delete-bin-line"></i></Button></td>
                                                        <td>Solid</td>
                                                        <td>This type Contains all Solid Waste Type</td>
                                                        <td><span className="badge bg-success">Active</span></td>

                                                    </tr>

                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs={4}>
                                <Card>
                                    <CardHeader className=''>
                                        <div>
                                            <h4 className="card-title mb-0">Master Wast Genration Type for Wast Managment</h4>
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        <div>
                                            About Master Wast Genration Type of Wast Managment, this Table for we Mark all Master Type of Wasts in any whare for all projects
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/* Add Modal */}
                <Modal isOpen={modal_list} toggle={() => { tog_list(); }} centered size="lg" >
                    <ModalHeader className=" p-3 text-white" toggle={() => { tog_list(); }}> Add Wast Genration Type to Master </ModalHeader>
                    <form className="tablelist-form">
                        <ModalBody>
                            <Row>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="companyName" className="form-label">Type Name</label>
                                    <input type="text" id="companyName" className="form-control" placeholder="Enter Name" required />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="about" className="form-label">Status</label>
                                    <select className="form-select" aria-label="Default select example">
                                        <option value={1} selected={true}>Yes</option>
                                        <option value={0} >No</option>
                                    </select>
                                </div>
                                <div className="mb-3 col-md-12">
                                    <label htmlFor="companyName" className="form-label">About Type</label>
                                    <input type="text" id="companyName" className="form-control" placeholder="About" required />
                                </div>

                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <div className="hstack gap-2 justify-content-center text-center">
                                <button type="button" className="btn btn-light" onClick={() => tog_list()}>Close</button>
                                <button type="submit" className="btn btn-success" id="add-btn">Add Type to Master</button>
                                {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                            </div>
                        </ModalFooter>
                    </form>
                </Modal>
                {/* Update Modal */}
                <Modal isOpen={modal_edit} toggle={() => { edit_company(); }} centered size="lg" >
                    <ModalHeader className=" opacity-50 p-3 text-white " toggle={() => { edit_company(); }}> Update Wast Genration Type to Master </ModalHeader>
                    <form className="tablelist-form">
                        <ModalBody>
                            <Row>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="companyName" className="form-label">Type Name</label>
                                    <input type="text" id="companyName" className="form-control" placeholder="Enter Name" required />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="about" className="form-label">Status</label>
                                    <select className="form-select" aria-label="Default select example">
                                        <option value={1} selected={true}>Yes</option>
                                        <option value={0} >No</option>
                                    </select>
                                </div>
                                <div className="mb-3 col-md-12">
                                    <label htmlFor="companyName" className="form-label">About Type</label>
                                    <input type="text" id="companyName" className="form-control" placeholder="About" required />
                                </div>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <div className="hstack gap-2 justify-content-center text-center">
                                <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Close</button>
                                <button type="submit" className="btn btn-success" id="add-btn">Upadte Now</button>
                                {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                            </div>
                        </ModalFooter>
                    </form>
                </Modal>
            </React.Fragment >
    );
};

export default MasterWastGenrationType;