import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row, Table, Input, Label, Card, CardHeader, CardBody, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import axios from 'axios';
import { toast } from 'react-toastify';
import Select from "react-select";
import { useProfile } from "../../Components/Hooks/UserHooks";
import Loader from "../../Components/Common/Loader";
const config = require('../../config.json');
const ProjectUser = () => {
    document.title = "Project User Mapping | ZWC";
    const { userProfile, loading, token } = useProfile();
    const [modal_list, setmodal_list] = useState(false);
    const [modal_edit, setmodal_edit] = useState(false);
    const [data, setData] = useState({});
    const [dissortBy, setdissortBy] = useState(null);
    const [disable, setdisable] = useState(false);
    const dissortbyname = [
        {
            options: [
                { label: "London", value: "London" },
                { label: "Manchester", value: "Manchester" },
                { label: "Liverpool", value: "Liverpool" }
            ]
        },
    ];

    const username = [
        {
            options: [
                { label: "Alabama", value: "AL" },
                { label: "Madrid", value: "MA" },
                { label: "Toronto", value: "TO" },
                { label: "Londan", value: "LO" },
                { label: "Wyoming", value: "WY" }
            ],
        },
    ];
    const tog_list = () => {
        setmodal_list(!modal_list);
    };
    const edit_company = () => {
        setmodal_edit(!modal_edit);
    };

    const set_update = (json) => {
        setmodal_edit(true);
    }

    const handelTeam = useCallback(() => {
        let axiosConfig = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${config.baseUrl}company/get_all_companys`,
            headers: {
                'Content-Type': 'application/json'
            },
        };
        axios.request(axiosConfig)
            .then((response) => {
                console.log(response)
                setData(response);
            })
            .catch((error) => {
                console.log(error);
                toast.error(`Error : ${error.response?.data?.message || error.message}`);
            });
    })
    useEffect(() => {
        //handelTeam()
    }, [userProfile])
    return (
        (false) ? <Loader /> :
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title="Project User Mapping" pageTitle="Zwc" />
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardHeader className='bg-success-subtle'>
                                        <Row >
                                            <Col className="col-12">
                                                <div>
                                                    <h4 className="card-title mb-0">Select Project to search User's</h4>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col className='col-8'>
                                                <Select
                                                    value={dissortBy}
                                                    onChange={(sortBy) => {
                                                        setdissortBy(sortBy);
                                                    }}
                                                    options={dissortbyname}
                                                    className="js-example-disabled mb-0"
                                                    isDisabled={disable}
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardHeader className='bg-success-subtle'>
                                        <Row >
                                            <Col className="col-sm-auto">
                                                <div>
                                                    <h4 className="card-title mb-0">Project Users Details</h4>
                                                </div>
                                            </Col>
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <Button color="info" className="add-btn me-1 rounded-pill" size='sm' onClick={() => tog_list()} id="create-btn"><i className="ri-add-line align-bottom me-1"></i> Add</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="table-responsive">
                                            <Table className="align-middle table-nowrap mb-0 table-sm table-hover">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Sr No</th>
                                                        <th scope="col">Project Name</th>
                                                        <th scope="col">Contact Person</th>
                                                        <th scope="col">Contact Number</th>
                                                        <th scope="col">Contact Email</th>
                                                        <th scope="col">Role</th>
                                                        <th scope="col">Edit Role </th>
                                                        <th scope="col">User Name</th>
                                                        <th scope="col">Conatct</th>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr>
                                                        <td scope="row">#1</td>
                                                        <td>Project Name</td>
                                                        <td>CNAME</td>
                                                        <td>Number</td>
                                                        <td>Email</td>
                                                        <td>Role</td>
                                                        <td><Button color="info" className="add-btn me-1 rounded-pill" size='sm' onClick={() => set_update(1)} ><i className="ri-pencil-fill"></i> Change</Button></td>
                                                        <td>User Name</td>
                                                        <td>+91123123123</td>
                                                        <td>user@test.com</td>
                                                        <td><span className="badge bg-success">Confirmed</span></td>
                                                        <td><Button color="warning" className="link-danger rounded-pill" size='sm' onClick={() => set_update(1)} ><i className="ri-delete-bin-line"></i>Remove</Button></td>
                                                    </tr>

                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/* Add Modal */}
                <Modal isOpen={modal_list} toggle={() => { tog_list(); }} centered size="lg" >
                    <ModalHeader className=" p-3 text-white" toggle={() => { tog_list(); }}> Add User to Project with Designation </ModalHeader>
                    <form className="tablelist-form">
                        <ModalBody>
                            <Row>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="companyName" className="form-label">Company Name</label>
                                    <input type="text" id="companyName" className="form-control" value="Company Name" readOnly />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="website" className="form-label">Select User</label>
                                    <Select
                                        value={username}
                                        onChange={(sortBy) => {
                                            setdissortBy(sortBy);
                                        }}
                                        options={dissortbyname}
                                        className="js-example-disabled mb-0"
                                        isDisabled={disable}
                                    />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="website" className="form-label">Select Role</label>
                                    <Select
                                        value={username}
                                        onChange={(sortBy) => {
                                            setdissortBy(sortBy);
                                        }}
                                        options={dissortbyname}
                                        className="js-example-disabled mb-0"
                                        isDisabled={disable}
                                    />
                                </div>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <div className="hstack gap-2 justify-content-center text-center">
                                <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Close</button>
                                <button type="submit" className="btn btn-success" id="add-btn">Map User Now</button>
                                {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                            </div>
                        </ModalFooter>
                    </form>
                </Modal>
                {/* Update Modal */}
                <Modal isOpen={modal_edit} toggle={() => { edit_company(); }} centered size="lg" >
                    <ModalHeader className=" opacity-50 p-3 text-white " toggle={() => { edit_company(); }}> Update User Designation </ModalHeader>
                    <form className="tablelist-form">
                        <ModalBody>
                            <Row>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="companyName" className="form-label">Company Name</label>
                                    <input type="text" id="companyName" className="form-control" value="Company Name" readOnly />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="companyName" className="form-label">User Name</label>
                                    <input type="text" id="companyName" className="form-control" value="Name of User" readOnly />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="website" className="form-label">Change Role</label>
                                    <Select
                                        value={username}
                                        onChange={(sortBy) => {
                                            setdissortBy(sortBy);
                                        }}
                                        options={dissortbyname}
                                        className="js-example-disabled mb-0"
                                        isDisabled={disable}
                                    />
                                </div>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <div className="hstack gap-2 justify-content-center text-center">
                                <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Close</button>
                                <button type="submit" className="btn btn-success" id="add-btn">Change Now</button>
                                {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                            </div>
                        </ModalFooter>
                    </form>
                </Modal>
            </React.Fragment>
    );
};

export default ProjectUser;