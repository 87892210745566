import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./slices";
import 'react-toastify/dist/ReactToastify.css';
const store = configureStore({ reducer: rootReducer, devTools: true });
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <React.Fragment>
        <BrowserRouter>
          <App />
          <ToastContainer
            position="top-right"  // You can adjust the position
            autoClose={5000}      // Auto close after 5 seconds
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </BrowserRouter>
      </React.Fragment>
    </Provider>
  </React.StrictMode>

);

reportWebVitals();
