import React, { useEffect, useState } from "react";
import { Navigate, Route } from "react-router-dom";

import { useProfile } from "../Components/Hooks/UserHooks";


import axios from "axios";
const AuthProtected = (props) => {
  const { userProfile, loading, token } = useProfile();
  let isLoggedIn = false
  if (userProfile && !loading && token) {
    isLoggedIn = true;
  }
  const setAuthorization = (token) => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  };
  useEffect(() => {
    //console.log(loading, token);
    if (userProfile && !loading && token) {
      setAuthorization(token);

    } else if (!userProfile && loading && !token) {
      sessionStorage.removeItem("authUser");
    }
  }, [token, userProfile, loading]);

  /*
    Navigate is un-auth access protected routes via url
    */
  if (!isLoggedIn) {

    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (<> <Component {...props} {...useProfile} /> </>);
      }}
    />
  );
};

export { AuthProtected, AccessRoute };